.img-certificates {
	width: 157px;
	margin-left: 110px;
}

.swiper-wrapper {
	-webkit-transition-timing-function: linear !important;
	-o-transition-timing-function: linear !important;
	transition-timing-function: linear !important;
}


.swiper-slide {
	height: auto;
}

/* body {
	align-items: center;
	background: #E3E3E3;
	display: flex;
	height: 100vh;
	justify-content: center;
} */

@mixin white-gradient {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

/* $animationSpeed: 40s; */


@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-250px * 7))
	}
}



@media only screen and (min-width:768px) and (max-width: 1800px) {
	.certificatslide .slider {
		height: 176xpx;
		margin: auto;
		overflow: hidden;
		position: relative;
		margin-left: 200px;
		margin-right: 200px;
	}
}

@media only screen and (max-width: 480px) {
	.certificatslide .slider {
		height: 176xpx;
		margin: auto;
		overflow: hidden;
		position: relative;

	}
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
	.certificatslide .slider {
		height: 176xpx;
		margin: auto;
		overflow: hidden;
		position: relative;
		margin-left: 100px;
		margin-right: 100px;
	}
}

.certificatslide .slider {
	/* background: white; */
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */

	/* width: 960px; */

	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}

	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	.slide-track {
		animation: scroll 40s linear infinite;
		display: flex;
		width: calc(250px * 14);
	}

	/* .slide {
		height: 100px;
		width: 250px;
	} */
}
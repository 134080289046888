.img-over img{
    width: 400px;
    border-radius: 10px;
}
@media screen and (max-width: 480px){
    .img-over img {
        width: 340px;
        border-radius: 10px;
    }
}
@media screen and (max-width: 480px){
    .colmt5 {
        width: 100%;
    }
}
.overview{
    background-color: lightgray;
}
.icon-border{
    border-style: solid;
    border-color: orange;
    padding: 10px;
}
.dflex{
    justify-content: space-evenly;
    margin-bottom: 26px;

}
.dflex1{
    margin-left: -12px;
}
.textyellow{
    color: orange;
}
/* .fontfamily{
    font-family: 'poppins',sans-serif;
} */
.whywork{
    color: orange;
    font-weight: bold;
}
.textalign{
    text-align: justify;
}
.title-usege{
    color: orange;
}

.carousel .slide img {
    /* width: 50% !important; */
    /* border-radius: 50%; */
  }
  
  h3{
    color: orange;
    font-weight: 900;
  }

  .usagecarousel img{
    height: 76vh;
    opacity: .5;
    
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
   

  }
  


/* .usagecarousel::before {
 
  position: absolute;
  
  background-color: rgba(0,0,0,0.25);
} */



.bgtext {
 
  color: rgb(0, 0, 0);
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: justify;
}
.usagetittle{
  font-size: 26px;
    font-weight: 700;
}
  
.carouselcaption {
  position: absolute;
  right: 15%;
  bottom: 14.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
  



.carousel.carousel-slider {
  width: 100%;
  height: 76vh;
}

.carousel-caption.d-none.d-md-block.carouselcaption {
  height: 23vh;
}


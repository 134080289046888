@media only screen and (max-width: 480px) {
    .newimg {
       margin-left: 0px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 3000px) {
    .newimg {
       margin-left: 225px;
    }
}
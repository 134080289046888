.bgcolor{
    background-color: #0a71a8;

}
/* #45b653 */
.textcenter{
  align-items: center ;
}

.navitem{
    font-size: 17px;
    font-weight: 640; 
}
.containerfluid img{
    width: 116px;
}

 a:hover {
    /* background-color: yellow; */
    color: rgb(12, 114, 168);
  }

  .navitem a {
    text-decoration: none;
  }
  .navitem button{
    border-style: none;
    background-color: white;
    font-size: initial;
    font-weight: 500;
  }
  .navitem a{
    
    background-color: white;
    font-size: initial;
    font-weight: 500;
  }

   .navitem > button:hover {
    color: orange;
  }
  .applytopimg img{
    width: -webkit-fill-available;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }

.tophome{
  background-color: orange;
}
.topemail span{
  color: green;
  font-weight: 700;
}
.topemail p{
  margin-top: 4px;
    margin-bottom: -4px;

}
@media only screen and (max-width: 480px){
  .tophome{
    display: none;
  }
}
.fnt{
  font-family: Trebuchet MS;
  
}
.nav-item::after{content:'';display:block;width:0px;height:2px;background:#fec400;transition: 0.2s;}
.nav-item:hover::after{width:100%;}
/* .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link,.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{color:#fec400;} */
.nav-link{padding:15px 5px;transition:0.2s;}
.dropdown-item.active, .dropdown-item:active{color:#212529;}
.dropdown-item:focus, .dropdown-item:hover{background:#fec400;}
.brand-section{
    background-image: url('../../../../public/images/imgbrand-back.jpeg');
    height: 569px;
}
.brand-images{
    border-radius: 10px;
    transition: 10s;
    animation-name: inherit;
}
/* .brand-card img{
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
} */
.brand-card{
    
    border-radius: 12px;
    position: relative;
    top: -276px;
    display: none;  
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease; */
}
.main-col-cont{
   height: 400px;
}
.main-col-cont:hover .brand-card{
    display: block;   
    transition: 4s ease;  
}
.main-col-cont2:hover .brand-card{
    display: block;   
    transition: 4s ease;  
}

.colcontain{
    padding-top: 180px;
}
.colcontain h4{
    font-family: Raleway;
    text-transform: uppercase;
    line-height: 45px;
    font-weight: 700;
    font-style: normal;
    color: white;
    font-size: 35px;
}
.row-pad{
    /* padding-top: 39px; */
    margin-left: 141px;

}
.n-g-s-l{
    padding: 1px;
}

.productshomw{
    background-color: #f8f8f8;
    padding: 60px 0 !important;
}

.brandtitle h4{
    font-family: playfair display;
    font-size: 17px !important;
    font-weight: 800;
    /* color: rgb(12, 114, 168) */
    color: orange;
}

.hoverimgb:hover{
    transform: scale(1.15);

}

.hoverimgb{
    transition: all 0.4s;
}
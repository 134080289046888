.bgevent{
    background-color: lightgray;
}


.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: rgba(var(--background-color-rgb), 0.9);
    padding: 15px;
  }

  .portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    font-family: var(--heading-font);
  }
  
  .portfolio .portfolio-filters li:hover,
  .portfolio .portfolio-filters li.filter-active {
    color: var(--contrast-color);
    background-color: var(--primary-color);
  }
  
  .portfolio .portfolio-filters li:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-filters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-filters li {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
  
  .portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: rgba(var(--background-color-rgb), 0.9);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: rgba(var(--default-color-rgb), 0.7);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: rgba(var(--default-color-rgb), 0.7);
    transition: 0.3s;
    line-height: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: var(--primary-color);
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }

  
  @media only screen and (max-width: 480px){
    .evnt{
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media only screen and (min-width:481px) and (max-width: 3000px) {
    .evnt{
        padding-left: 100px;
      padding-right: 116px;
    }
  
  }
.titlebar {

    width: 100%;
    text-align: left;
    background-size: cover;
    border-top: 1px solid #ebeded;
    border-bottom: 1px solid #ebeded;
    position: relative;
    /* margin-bottom: 60px; */
    background-color: darkblue;
}

.titlebar-home {
    text-decoration: none;
}

.titlebarli {
    margin-right: 11px;
}

.titlebarli2 {
    margin-left: 10px;
}

@media only screen and (max-width: 480px) {
    .main-head-color {
        color: orange;
    }
}

@media only screen and (min-width: 64.0625em) {

    .column,
    .columns {
        position: relative;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        float: left;
    }
}

@media only screen and (min-width: 40.0625em) {

    .column,
    .columns {
        position: relative;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        float: left;
    }
}

.titlebar>.row1 {
    z-index: 1;
    position: relative;
}

.row1 {
    margin: 0 auto;
    max-width: 81.25em;
    width: 100%;
}

.left {
    float: left !important;
}

.titlebar .title {
    font-size: 2.714em;
    line-height: 1.265em;
    color: orange;
}

@media only screen and (min-width: 770px) {
    .titlebar .title {
        margin: 1em 0;
    }
}

.page h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@media only screen and (min-width: 40.0625em) {
    .textabout h1 {
        font-size: 2.75rem;
    }
}

.right {
    float: right !important;
}

@media only screen and (max-width: 480px) {
    .right {
        float: none;
    }
}

/* @media only screen and (max-width: 480px) {
    .columns {
        display: none;
    }
} */

/* .breadcrumbs,
.breadcrumbs a,
.breadcrumbs>.current a {
    color: rgba(255, 255, 255, 0.85);

} */

.breadcrumbs {
    background: none;
    border: none;
    display: flex;
}

.breadcrumbs li {
    list-style-type: none;
}

@media only screen and (min-width: 770px) {
    .breadcrumbs {
        margin-top: 55px;
    }
}

.titlebar {
    background: white;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, white, white);
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #4A00E0, #8E2DE2); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


/* * {box-sizing: border-box} */

/* Container needed to position the overlay. Adjust the width as needed */
.containerteam {
  position: relative;
  width: 100%;
  /* max-width: 300px; */
}

/* Make the image to responsive */
/* .teamimg {
  display: block;
  width: 100%;
  height: auto;
} */

/* The overlay effect - lays on top of the container and over the image */
.overlayteam {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

/* When you mouse over the container, fade in the overlay title */
.containerteam:hover .overlayteam {
  opacity: 1;
  display: block;
}

.textalg{
    text-align: center;
}

/* .topback{
    background-position: start;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(65deg, rgba(16, 16, 16, 0) -167%, #61675e 200%), url('../../../public/images/improtopback.jpg');
  opacity: 0.72;
  transition:  0.3s, border-radius 0.3s, opacity 0.3s;
} */


.textalign{
    text-align: justify;
}
.fnt1{
    font-family: cabin;
}
.row-cont{
    box-shadow: 1px 17px 6px gray;
    background-color: white;
}
.containermt{
    padding: 27px;
}
.contactinf{
    background-color: orange;
    padding: 27px;
}
.btn-color{
    background-color: orange;
    width: 100%;
}

.message {
    background-color: #f0f0f0;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
#mySidenav a {
  position: absolute;
  left: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 100px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  border-radius: 0 5px 5px 0;
  top: 218px;
}

#blog {
  top: 80px;
  background-color: white;
}

#mySidenav a:hover {
  left: 0;
}



/* Container holding the image and the text */
.container1 {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered1 {
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;
  color: orange;
}

.container1 img {
  -webkit-filter: blur(5px);
  /* Safari 6.0 - 9.0 */
  filter: blur(1px);
  height: 578px;
}

.centeredhead {
  font-size: 27px;
  /* line-height: 0; */
}




/* new */
.bg-text {
 
  color: rgb(0, 0, 0);
  /* font-weight: bold; */
  
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: justify;
}

@media only screen and (max-width: 480px) {
  .bg-text {
    margin-top: -146px;
  }

}
@media only screen and (min-width:480px) and (max-width: 767px) {
  .bg-text {
    margin-top: -392px;
  }

}
@media only screen and (min-width:768px) and (max-width: 1800px) {
  .bg-text {
    margin-top: -401px;
  }

}

@media only screen and (max-width: 480px) {
  .mototitle span {
    font-size: 9px;
    font-family: "Trebuchet MS";
  }

}
@media only screen and (min-width:480px) and (max-width: 767px) {
  .mototitle span {
    font-weight: 700;
    font-family: "Trebuchet MS";
     font-size: 23px;
  }

}
@media only screen and (min-width:768px) and (max-width: 1800px) {
  .mototitle span {
    font-weight: 700;
    font-family: "Trebuchet MS";
     font-size: 2rem;
  }

}

.bg-image {
  /* The image used */
  /* background-image: url("../../../../public/images/hslln.jpeg"); */
  /* background-image: url('../../../../public/images/imgbrand-back.jpeg'); */

  /* Add the blur effect */
  /* filter: blur(8px);
  -webkit-filter: blur(2px); */

  /* Full height */
  height: 598px;

  /* Center and scale the image nicely */
  background-position: start;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(65deg, rgba(16, 16, 16, 0) -167%, #61675e 200%), url('../../../../public/images/improimg/improimg\ \(14\).jpg');
  opacity: 0.3;
  transition: 0.3s, border-radius 0.3s, opacity 0.3s;
  border-bottom: 15px solid white;
}


@media only screen and (max-width: 480px) {
  .mototitle h1 {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    font-family: "Raleway", sans-serif;
    line-height: 1.2;
    margin-top: -139px;

  }
}

@media only screen and (min-width:768px) and (max-width: 1800px) {
  .mototitle h1 {
    /* color: #fff;
    margin: 10px 0 0 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    font-family: "Raleway", sans-serif; */
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    font-family: "Raleway", sans-serif;
    line-height: 1.2;

  }
}

@media only screen and (max-width: 480px) {
  .bg-image {
    height: 235px;
  }
}

@media only screen and (max-width: 1880px) {
  .mototitle {
    /* display: 10px; */
    margin-top: 180px;
  }
}

@media only screen and (max-width: 480px) {
  .mototitle {
    /* display: 10px; */
    margin-top: 0px;
  }
}

/* @media only screen and (min-width:768px) and (max-width: 1800px) {
  .videotop {
    margin-top: -107px;
    margin-bottom: -113px;
  }
} */

/* @media only screen and (max-width: 480px) {
  .videotop {
    margin-top: -32px;
    margin-bottom: -36px;
  }
} */

/* @media only screen and (min-width: 200px) and (max-width: 470px) {
  .videotop {
    margin-top: -16px;
    margin-bottom: -20px;
  }
} */

/* @media only screen and (min-width: 480px) and (max-width: 768px) {
  .videotop {
    margin-top: -53px;
    margin-bottom: -58px;
  }
} */

/* style={{fontSize: '19px', paddingRight: "368px",lineHeight: "1.8"}} */
@media only screen and (min-width:1201px) and (max-width: 1800px) {
  .tophometext {
     font-size: 18px;
     padding-right: 368px;
     line-height: 1.8;
  }
}
@media only screen and (min-width:768px) and (max-width: 1200px) {
  .tophometext {
     font-size: 14px;
     padding-right: 280px;
     /* line-height: 1.8; */
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .tophometext {
    font-size: 14px;
    padding-right: 0px;
   
 }
}

@media only screen and (max-width: 480px) {
  .tophometext {
    font-size: 9px;
    padding-right: 0px;
    /* line-height: 0; */
 }
}

/* .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
} */